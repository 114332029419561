import React, { lazy } from 'react';
import { DefaultLayout } from '@layouts';
import { Suspense } from '@/components';

import appRoutes from './appRoutes';

const Login = lazy(() => import('@modules/login'));
const SSO = lazy(() => import('@modules/sso'));
const NotFound = lazy(() => import('@modules/error/notFound'));

export default [
  {
    path: '/',
    element: <DefaultLayout />,
    children: appRoutes,
  },
  {
    // 开发模式的登录
    path: '/login',
    element: <Suspense><Login /></Suspense>,
  },
  {
    // sso service ticket 登录
    path: '/sso',
    element: <Suspense><SSO /></Suspense>,
  },
  {
    path: '*',
    element: <Suspense><NotFound /></Suspense>,
  },
];
